<template>
  <div>
    <h2>Project Management</h2>
    <projects />
  </div>
</template>

<script>
import Projects from '@/components/Projects/Projects.vue'

export default {
  components: {
    Projects,
  },
}
</script>
