var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"title":((_vm.projectId ? 'Update' : 'Create') + " Project"),"centered":""},on:{"hidden":function($event){return _vm.$emit('modal-closed')},"ok":_vm.onSubmit},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){
var ok = ref.ok;
var cancel = ref.cancel;
return [_c('b-button',{attrs:{"variant":"secondary"},on:{"click":function($event){return cancel()}}},[_vm._v(" Cancel ")]),_c('b-button',{attrs:{"variant":_vm.projectId && _vm.projectName === _vm.project.name && _vm.emailBox === _vm.project.email_box? 'outline-secondary' : 'primary',"type":"submit","disabled":_vm.submitting || _vm.loading || (_vm.projectId && _vm.projectName === _vm.project.name && _vm.emailBox === _vm.project.email_box)},on:{"click":function($event){return ok()}}},[_vm._v(" "+_vm._s(_vm.projectId ? 'Update' : 'Create')+" "),(_vm.submitting)?_c('b-spinner',{attrs:{"small":"","label":"Small Spinner"}}):_vm._e()],1)]}}]),model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[(!_vm.loading)?_c('div',[_c('validation-observer',{ref:"projectForm"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"Project Name","vid":"projectName","mode":"eager","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Project Name","label-for":"project-name","label-cols-md":"4","state":errors.length > 0 ? false:null}},[_c('b-form-input',{attrs:{"id":"project-name","placeholder":"Project Name"},on:{"input":function($event){_vm.errorMessage = null}},model:{value:(_vm.projectName),callback:function ($$v) {_vm.projectName=$$v},expression:"projectName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,2268965752)}),_c('validation-provider',{attrs:{"name":"Email Box","vid":"emailBox","mode":"eager","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Email Box","label-for":"email-box","label-cols-md":"4","state":errors.length > 0 ? false:null}},[_c('b-form-input',{attrs:{"id":"email-box","placeholder":"Email Box"},on:{"input":function($event){_vm.errorMessage = null}},model:{value:(_vm.emailBox),callback:function ($$v) {_vm.emailBox=$$v},expression:"emailBox"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(_vm.isMailDuplicate)?_c('small',{staticClass:"text-danger"},[_vm._v("This email already taken ")]):_vm._e()],1)]}}],null,false,2527843952)})],1)],1),_c('b-alert',{staticClass:"my-1",attrs:{"variant":"danger","show":_vm.errorMessage !== null ? true : false}},[_c('div',{staticClass:"alert-body"},[_c('p',[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")])])])],1)],1)],1):_vm._e(),(_vm.loading)?_c('div',{staticClass:"text-center"},[_c('b-spinner',{attrs:{"variant":"primary"}})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }